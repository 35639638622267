export type McAccount = {
    username: string;
    uuid: string;
}

export const useAuthStore = defineStore('authStore', {
    state: () => ({
        mcaccounts: [] as McAccount[],
        auth: false,
    }),
    actions: {
        setMcaccounts(mcaccounts: McAccount[]) {
            this.mcaccounts = mcaccounts
        },
        addMcAccount(mcaccount: McAccount) {
            this.mcaccounts.push(mcaccount)
        },
        removeMcAccount(uuid: string) {
            this.mcaccounts = this.mcaccounts.filter(mcaccount => mcaccount.uuid !== uuid)
        },
        async checkAuth() {
            try {
                const token = useCookie('token')
                const response = await fetch('https://api.mineclub.dk/v1/discord/auth', {
                    headers: new Headers({
                        Authorization: `Bearer ${token.value}`,
                    }),
                });
                if (response.status === 200) {
                    const data = await response.json();
                    this.mcaccounts = data.data.mcaccounts;
                    this.auth = true;
                    return true;
                } else {
                    this.auth = false;
                    this.mcaccounts = [];
                    return false;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        }
    },
})  